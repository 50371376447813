<!--
* @FileDescription: 订单管理
* @Author: chenz
* @Date: 2021/4/28
 * @LastEditors: chenz
 * @LastEditTime: 2021-11-18 13:55:43
-->
<template>
  <div class="order-manage">
    <!-- 查询表单 -->
    <CommonQueryFrom
      :queryForm="queryForm"
      :queryFormItemList="queryFormItemList"
      @_add="_add"
      @_query="_query"
      @_export="_export"
    ></CommonQueryFrom>
    <!-- 表格 -->
    <CommonTable
      :tableData="tableData"
      :columns="columns"
      :loading="loading"
      :pagingOptions="pagingOptions"
      @_del="_del"
      @_edit="_edit"
      @_sizeChange="_sizeChange"
      @_currentChange="_currentChange"
      @_handleSelectionChange="_handleSelectionChange"
    >
    </CommonTable>
    <!-- 弹框表单 -->
    <CommonDialogForm
      ref="form"
      :form="form"
      :rules="rules"
      :visible="visible"
      :title="dialogTitle"
      :formItemList="formItemList"
      @_handleSelectChange="_handleSelectChange(arguments)"
      @_cancel="_cancel"
      @_confirm="_confirm"
      @_Close="_Close"
    >
    </CommonDialogForm>
  </div>
</template>

<script>
// 引入侧边栏json
const formItemList = require("./formItemList")

// 引入接口
import { list, save, update, del } from "../../api/meituan"

// 引入方法
import { getExcelLabelOrProp } from "../../utils/tool"

import {
  getStoreOptions,
  getProjectOptionsBySid,
} from "../../api/select-options"

export default {
  name: "MeituanManage",

  data() {
    return {
      // 查询表单
      queryForm: {
        orderId: "",
        realName: "",
      },

      // 查询表单列表
      queryFormItemList: [
        { type: "input", prop: "orderId", placeholder: "请输入要查询的订单号" },
        {
          type: "input",
          prop: "realName",
          placeholder: "请输入要查询的用户姓名",
        },
      ],

      // 列接受的参数
      columns: [
        { label: "店铺名称", prop: "storeName" },
        { label: "项目名称", prop: "projectName" },
        // { label: "用户姓名", prop: "realName", width: 200 },
        { label: "订单金额", prop: "payPrice" },
        { label: "创建时间", prop: "createTime" },
      ],

      // 具体数据
      tableData: [],

      // 选择的表格数据
      selectChangeTableData: [],

      // 分页
      pagingOptions: {
        limit: 10,
        page: 1,
        total: 0,
      },

      // 加载
      loading: false,

      // 弹框标题
      dialogTitle: "添加用户",

      // 弹框显示
      visible: false,

      // 表单
      form: {
        storeId: "",
        projectId: "",
        payPrice: "",
      },

      // 校验
      rules: {
        storeId: [
          { required: true, message: "店铺不能为空", trigger: "change" },
        ],
        payPrice: [
          { required: true, message: "项目不能为空", trigger: "change" },
        ],
        projectId: [
          { required: true, message: "订单金额不能为空", trigger: "change" },
        ],
      },

      // 表单数据
      formItemList: formItemList,

      storeId: undefined,
    }
  },

  mounted() {
    this.getStore()

    this.getData()
  },

  methods: {
    // 获取所属店铺
    async getStore() {
      try {
        const res = await getStoreOptions()
        const index = this.formItemList.findIndex((_) => _.prop === "storeId")
        this.formItemList[index].options = res.data.records
      } catch (error) {
        console.log(error)
      }
    },

    // 获取项目列表
    async getProjectOptionsBySid(e) {
      try {
        const res = await getProjectOptionsBySid(e || this.form.storeId)
        const index = this.formItemList.findIndex((_) => _.prop === "projectId")
        this.formItemList[index].options = res.data
      } catch (error) {
        console.log(error)
      }
    },
    // 添加
    _add() {
      this.form = {}
      // this.form.storeIds = []
      this.dialogTitle = "添加美团订单"
      this.visible = true
      this.$nextTick(() => {
        this.$nextTick(() => {
          this.$refs.form.$refs.form.clearValidate()
        })
      })
    },

    // 查询
    _query(form) {
      this.getData()
    },

    _handleSelectChange(args) {
      console.log("_handleSelectChange", args)
      var sid = args[0]
      this.getProjectOptionsBySid(sid)
    },

    // 多选事件
    _handleSelectionChange(val) {
      this.selectChangeTableData = val
      console.log("选择要导出的", this.selectChangeTableData)
    },

    // 导出
    _export(form) {
      console.log("导出", form)
      import("../../vendor/Export2Excel").then((excel) => {
        const tHeader = getExcelLabelOrProp("编号", "label", this.columns)
        const filterVal = getExcelLabelOrProp("serial", "prop", this.columns)
        const list =
          this.selectChangeTableData.length > 0
            ? this.selectChangeTableData
            : this.tableData
        const data = this.formatJson(filterVal, list)
        excel.export_json_to_excel({
          header: tHeader,
          data,
          filename: "订单管理",
          bookType: "xlsx",
        })
      })
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map((v) => filterVal.map((j) => v[j]))
    },

    // 修改
    _edit(row) {
      console.log("row", row)
      this.dialogTitle = "修改美团订单"
      this.visible = true
      this.form = Object.assign({}, row)
      this.$nextTick(() => {
        this.$nextTick(() => {
          this.$refs.form.$refs.form.clearValidate()
        })
      })
    },

    // 删除
    _del(row) {
      this.$confirm("你确定要删除此订单吗？, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const res = await del(row.id)
          if (res.code === 200) {
            this.getData()
          }
          this.$message({
            type: "success",
            message: "删除成功!",
          })
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          })
        })
    },

    // 每页多少条事件
    _sizeChange(val) {
      this.pagingOptions.limit = val
      this.getData()
    },

    // 当前页事件
    _currentChange(val) {
      this.pagingOptions.page = val
      this.getData()
    },

    // 取消
    _cancel() {
      this.closeForm()
    },

    // 确定
    _confirm() {
      // this.$refs.form.$refs.form.validate(async (valid) => {
      //   if (valid) {
      //     try {
      //       const res = await updateOrder(this.form.id, this.form)
      //       this.visible = false
      //       this.$message({ type: "success", message: "修改成功" })
      //       this.getData()
      //     } catch (error) {
      //       this.$message({ type: "error", message: "修改失败" })
      //     }
      //   } else {
      //     console.log("error submit!!")
      //     return false
      //   }

      // })

      this.$refs.form.$refs.form.validate(async (valid) => {
        if (valid) {
          !this.form.id ? this.add() : this.edit()
        } else {
          console.log("error submit!!")
          return false
        }
      })
    },

    async add() {
      // this.form.image = this.imageTemp
      const res = await save(this.form)
      if (res.code === 200) {
        this.visible = false
        this.$message({
          type: "success",
          message: "添加成功",
        })
        this.getData()
      }
    },

    async edit() {
      try {
        const res = await update(this.form.id, this.form)
        // console.log("修改接口", res)
        if (res.code !== 200) {
          return this.$message({
            showClose: true,
            message: "修改美团订单数据失败",
            type: "error",
          })
        }
        this.$message({
          showClose: true,
          message: "修改美团订单数据成功",
          type: "success",
        })
        this.visible = false
        this.getData()
      } catch (error) {}
    },

    // 表单关闭按钮
    _Close() {
      this.closeForm()
    },

    // 关闭表单
    closeForm() {
      // console.log(this.form)
      this.$refs.form.$refs.form.resetFields() // 重置表单
      this.$refs.form.$refs.form.clearValidate() // 移除表单校验
      this.visible = false // 关闭弹框
    },

    // 获取订单管理数据
    async getData() {
      const { limit, page } = this.pagingOptions
      this.loading = true
      try {
        const res = await list(limit, page)
        // console.log("获取订单管理数据", res)
        this.loading = false
        this.tableData = res.data.list || res.data.records
        this.pagingOptions.total = res.data.total
      } catch (error) {
        this.loading = false
        this.tableData = []
        this.pagingOptions.total = 0
        this.$message({
          showClose: true,
          message: "获取数据失败",
          type: "error",
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.order-manage {
  height: 100%;
  padding: 0 16px 0 16px;
  background-color: #fff;
}
</style>  